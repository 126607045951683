<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link
      :to="{name:'home-page'}"
      class="brand-logo"
    >
      <AppLogo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ t('Page Not Found') }} 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import AppLogo from '@core/layouts/components/AppLogo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import useHorizontalNavMenuHeaderLink
  from '../../../vuexy/src/@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-link/useHorizontalNavMenuHeaderLink'
import { useUtils as useI18nUtils } from '../../../vuexy/src/@core/libs/i18n'
import { useUtils as useAclUtils } from '../../../vuexy/src/@core/libs/acl'

export default {
  components: {
    AppLogo,
    BLink,
    BButton,
    BImg
  },
  data () {
    return {
      appName: $themeConfig.app.appName,
      downImg: require('@/assets/images/pages/error.svg')
    }
  },
  computed: {
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    }
  },
  setup (props) {
    const { t } = useI18nUtils()
    return {
      // i18n
      t
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
